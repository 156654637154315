import { cancellableTimer } from './cancellable-timer';

type WithTimeoutResult<T> = {
  completed: boolean;
  promise: Promise<T>;
};
export async function withTimeout<T>(
  promise: Promise<T>,
  timeout: number
): Promise<WithTimeoutResult<T>> {
  const timer = cancellableTimer(timeout);

  await Promise.race([promise, timer]);

  const completed = timer.isWaiting();

  if (completed) {
    timer.cancel();
  }

  return { completed, promise };
}
