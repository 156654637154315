import { Logger } from '../logger';

export type CancellableTimer = Promise<void> & {
  cancel: () => void;
  isWaiting: () => boolean;
};

export function cancellableTimer(
  timeoutMilliseconds: number
): CancellableTimer {
  let timeout: NodeJS.Timeout | undefined;

  let timer = new Promise((resolve) => {
    timeout = setTimeout(() => {
      timeout = undefined;
      resolve(undefined);
    }, timeoutMilliseconds);
  }) as CancellableTimer;

  timer = timer.catch((error: any) => {
    timeout = undefined;
    Logger.error('Cancellable timer callback failed', { error });

    throw error;
  }) as CancellableTimer;

  timer.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  };

  timer.isWaiting = () => !!timeout;

  return timer;
}
