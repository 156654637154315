import { GeolocationActions } from '$/app/store/geolocation/geolocation.actions';
import { GeolocationSelectors } from '$/app/store/geolocation/geolocation.selectors';
import { withDefault } from '$/lib/signals/with-default';
import { updateSplashScreenText } from '$/lib/splash-screen';
import {
  AlcGeolocation,
  UserGeolocation
} from '$shared/facility-settings/geolocation.types';
import { Logger } from '$shared/logger';
import { withTimeout } from '$shared/utils/with-timeout';
import { Injectable, inject, signal } from '@angular/core';
import { PermissionState } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { AppInfo } from './app-info';

type LocationServicesState = 'disabled' | 'prompt' | 'granted' | 'denied';

const DISABLE_WAITING_FOR_GEOLOCATION = false;
const GET_CURRENT_POSITION_TIMEOUT = 20 * 1000;

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  private readonly store = inject(Store);

  private clickHandlerInstalled = false;
  private lastGotGeolocationAt = DateTime.fromISO('2020-01-01');
  private positionPromise: Promise<Position>;

  public readonly geolocation = withDefault(
    this.store.selectSignal(GeolocationSelectors.selectGeolocation),
    { status: 'initial state' }
  );

  public readonly locationServicesState =
    signal<LocationServicesState>('prompt');

  public initialize() {
    void this.getCurrentGeolocation();

    this.listenToClicks();
  }

  public async updateLocationServicesState() {
    const status = await this.getLocationServicesState();

    this.locationServicesState.set(status);
  }

  public async waitForGeolocation(): Promise<void> {
    if (DISABLE_WAITING_FOR_GEOLOCATION) {
      return;
    }

    let geolocation = this.geolocation();

    if (geolocation.status === 'success') {
      return;
    }

    updateSplashScreenText('Determining your location...');

    geolocation = await this.getCurrentGeolocation();

    switch (geolocation.status) {
      case 'loading':
        updateSplashScreenText('Still determining your location...');
        break;

      case 'error':
        updateSplashScreenText('An error occurred getting your location');
        break;
    }
  }

  private listenToClicks() {
    if (this.clickHandlerInstalled) {
      return;
    }

    this.clickHandlerInstalled = true;

    document.addEventListener('click', () => {
      const status = this.geolocation().status;
      const elapsed = Math.abs(
        this.lastGotGeolocationAt.diffNow().as('seconds')
      );

      if (
        (elapsed > 60 && status === 'success') ||
        (elapsed > 10 && status !== 'success')
      ) {
        void this.getCurrentGeolocation();
      }
    });
  }

  private async getCurrentGeolocation(): Promise<UserGeolocation> {
    await this.updateLocationServicesState();

    if (
      this.locationServicesState() !== 'granted' &&
      this.locationServicesState() !== 'prompt'
    ) {
      return this.updateGeolocation({
        status: 'error',
        message: `Location services are ${this.locationServicesState()}`
      });
    }

    try {
      if (
        this.geolocation().status !== 'success' &&
        this.geolocation().status !== 'loading'
      ) {
        this.updateGeolocation({ status: 'loading' });
      }

      Logger.debug('Requesting geolocation from device');

      // We may have a position promise from the last attempt, if
      // it took longer than `timeout` to resolve. In that case,
      // we want to wait for it to resolve before trying again.
      this.positionPromise ??= Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: GET_CURRENT_POSITION_TIMEOUT,
        maximumAge: 30 * 1000
      });

      // Geolocation.getCurrentPosition() accepts a "timeout" value as seen above,
      // but it doesn't always honor the timeout.  So... we race the promise
      // against a timeout to ensure we don't hang indefinitely.
      const result = await withTimeout(
        this.positionPromise,
        GET_CURRENT_POSITION_TIMEOUT
      );

      if (!result.completed) {
        Logger.warn('Getting the user location is taking longer than expected');

        // We do not clear the promise for a timeout because
        // it might resolve after the timeout, allowing
        // us to use the result in our next location check.
        return this.updateGeolocation({
          status: 'error',
          message: 'Getting the current position timed out.'
        });
      }

      // The request completed, so we can clear the promise
      const position = await result.promise;
      this.positionPromise = null;

      return this.updateGeolocation({
        status: 'success',
        location: this.serializePosition(position)
      });
    } catch (error) {
      Logger.error('Error getting current geolocation', { error });

      // Clear the promise, we'll try again later
      this.positionPromise = null;

      return this.updateGeolocation({
        status: 'error',
        message: error?.message ?? 'unknown error'
      });
    }
  }

  private async getLocationServicesState(): Promise<LocationServicesState> {
    try {
      // The following will throw if location services on the mobile device are disabled
      const permissionStatus = await Geolocation.checkPermissions();

      Logger.debug('Geolocation permission status:', { permissionStatus });

      // "Geolocation.requestPermissions()" is not supported on the web, so we can't
      // directly prompt the user to enable location services.  We'll just let the browser
      // handle it when we try to get the user's location.
      if (
        AppInfo.deviceInfo.platform === 'web' ||
        !permissionStatus.location.startsWith('prompt')
      ) {
        return this.toLocationServicesState(permissionStatus.location);
      }

      // The following will throw if location services on the mobile device are disabled
      const requestStatus = await Geolocation.requestPermissions();

      return this.toLocationServicesState(requestStatus.location);
    } catch (error: any) {
      Logger.error(
        'An error occurred while checking/requesting geolocation permission.',
        { error }
      );

      return 'disabled';
    }
  }

  private toLocationServicesState(
    permissionState: PermissionState
  ): LocationServicesState {
    return permissionState === 'prompt-with-rationale'
      ? 'prompt'
      : permissionState;
  }

  private updateGeolocation(geolocation: UserGeolocation): UserGeolocation {
    Logger.debug('Geolocation updated', { geolocation });

    this.lastGotGeolocationAt = DateTime.now();

    this.store.dispatch(
      GeolocationActions.saveGeolocation({
        geolocation: {
          ...geolocation,
          timestamp: DateTime.local().toISO()
        }
      })
    );

    return geolocation;
  }

  // NOTE: Position.coords is not serializable by default, which causes it to be an empty object
  // when saved to the store. So we turn it into a POJO here.
  private serializePosition(position: Position): AlcGeolocation {
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      altitudeAccuracy: position.coords.altitudeAccuracy,
      altitude: position.coords.altitude,
      speed: position.coords.speed,
      heading: position.coords.heading
    };
  }
}
